import _adapter_factory2 from "./adapter_factory.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _adapter_factory = _adapter_factory2;
var adapter = (0, _adapter_factory.adapterFactory)({
  window: typeof window === "undefined" ? undefined : window
});
exports.default = adapter;
export default exports;
export const __esModule = exports.__esModule;